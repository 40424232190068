import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const pageIntro = (delay = 0.5) => {
  const view = viewStorage.current.dataset.taxiView

  const pageDOM = {
    // Header / Hero
    header: viewStorage.current.children[0],
    headerTitle: viewStorage.current.children[0].querySelector('h1'),
    headerButtons: viewStorage.current.children[0].querySelectorAll('.Btn'),
    headerVisual: viewStorage.current.children[0].querySelector('.visual__container .visual'),
    headerBreadcrumb: viewStorage.current.children[0].querySelector('.Breadcrumb'),
    headerContent: viewStorage.current.children[0].querySelector('.content'),
    headerForm: viewStorage.current.children[0].querySelector('.Form'),
    
    // Sections
    allSectionsExceptTheFirst: [...viewStorage.current.children].slice(1),

    // About
    aboutWysiwyg: viewStorage.current.children[0].querySelector('.wswyg--content'),
    aboutVideo: viewStorage.current.children[0].querySelector('.video'),

    // Article
    articleBackLink: viewStorage.current.children[0].querySelector('.Link'),
    articleSharing: viewStorage.current.children[0].querySelector('.Sharing'),

    // Offer Details
    offerDetailsVisual: viewStorage.current.children[0].querySelector('.visual-wrapper .visual__container'),
    offerDetailsIcon: viewStorage.current.children[0].querySelector('.Icon.--watermark'),
  }

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power4.out', duration: 0.8 },
      onStart: () => {
        ScrollTrigger.refresh()

        // Stop Scroll
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

        // Reset scroll with timing for Chrome
        setTimeout(() => window.scrollTo(0, 0), 25)
      },
      onComplete: () => {
        ScrollTrigger.refresh()

        domStorage.body.classList.remove('--animating')
        domStorage.body.classList.remove('--intro')

        // Start Scroll
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

        // Clean styles
        pageDOM.headerTitle && gsap.set(pageDOM.headerTitle, { clearProps: 'all' })
        pageDOM.headerButtons && gsap.set(pageDOM.headerButtons, { clearProps: 'all' })
        pageDOM.headerBreadcrumb && gsap.set(pageDOM.headerBreadcrumb, { clearProps: 'all' })
        pageDOM.headerContent && gsap.set(pageDOM.headerContent, { clearProps: 'all' })
        pageDOM.headerForm && gsap.set(pageDOM.headerForm, { clearProps: 'all' })

        pageDOM.allSectionsExceptTheFirst && pageDOM.allSectionsExceptTheFirst.length && gsap.set(pageDOM.allSectionsExceptTheFirst, { clearProps: 'y,opacity' })

        if (view === 'pageHome') {
          pageDOM.headerVisual && gsap.set(pageDOM.headerVisual, { clearProps: 'all' })
        }

        if (view === 'pageOurStory') {
          pageDOM.aboutWysiwyg && gsap.set(pageDOM.aboutWysiwyg, { clearProps: 'all' })
          pageDOM.aboutVideo && gsap.set(pageDOM.aboutVideo, { clearProps: 'all' })
        }

        if (view === 'singlePost') {
          pageDOM.articleSharing && gsap.set(pageDOM.articleSharing, { clearProps: 'all' })
          pageDOM.articleBackLink && gsap.set(pageDOM.articleBackLink, { clearProps: 'all' })
          pageDOM.headerVisual && gsap.set(pageDOM.headerVisual, { clearProps: 'all' })
        }

        pageDOM.offerDetailsVisual && gsap.set(pageDOM.offerDetailsVisual, { clearProps: 'scale' }, 0)
        pageDOM.offerDetailsIcon && gsap.set(pageDOM.offerDetailsIcon, { clearProps: 'scale,opacity,rotate' })
      }
    })
    
    // Headers
    pageDOM.headerTitle && tl.fromTo(pageDOM.headerTitle, { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0)
    pageDOM.headerButtons && pageDOM.headerButtons.length && tl.fromTo(pageDOM.headerButtons, { yPercent: 30, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.15)
    pageDOM.headerBreadcrumb && tl.fromTo(pageDOM.headerBreadcrumb, { yPercent: 25, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.5)
    pageDOM.headerContent && tl.fromTo(pageDOM.headerContent, { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.15)
    pageDOM.headerForm && tl.fromTo(pageDOM.headerForm, { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.2)
    
    // Homepage
    if (view === 'pageHome') {
      pageDOM.headerVisual && tl.from(pageDOM.headerVisual, { scale: 1.2, duration: 1 }, 0)
    }

    // Our Story
    if (view === 'pageOurStory') {
      pageDOM.aboutWysiwyg && tl.fromTo(pageDOM.aboutWysiwyg, { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.1)
      pageDOM.aboutVideo && tl.fromTo(pageDOM.aboutVideo, { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 }, 0.25)
    }
    
    // Article
    if (view === 'singlePost') {
      pageDOM.articleSharing && tl.fromTo(pageDOM.articleSharing, { opacity: 0, yPercent: 20 }, { opacity: 1, yPercent: 0 }, 0.15)
      pageDOM.articleBackLink && tl.fromTo(pageDOM.articleBackLink, { opacity: 0 }, { opacity: 1 }, 0.5)
      pageDOM.headerVisual && tl.from(pageDOM.headerVisual, { scale: 1.2, duration: 1 }, 0)
    }

    // Offer Details && Gutenberg
    pageDOM.offerDetailsVisual && tl.from(pageDOM.offerDetailsVisual, { scale: 1.2, duration: 1 }, 0)
    pageDOM.offerDetailsIcon && tl.fromTo(pageDOM.offerDetailsIcon, { scale: 0.75, opacity: 0, rotate: 4 }, { scale: 1, opacity: 1, rotate: 0 }, 0.15)

    pageDOM.allSectionsExceptTheFirst && pageDOM.allSectionsExceptTheFirst.length && tl.fromTo(pageDOM.allSectionsExceptTheFirst, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.4')

  return tl
}
