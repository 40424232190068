import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import WknSwiper from '../modules/wkn-swiper'
import Accordions from '../modules/accordions'

export default class PageGutenberg extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      presentationCardsSwipers: viewStorage.current.querySelectorAll('.PresentationCards .swiper.--mobile'),
      relatedProfilesSwipers: viewStorage.current.querySelectorAll('.RelatedProfiles .swiper'),
      relatedQuestions: viewStorage.current.querySelectorAll('.RelatedQuestions'),
      accompanimentSwipers: viewStorage.current.querySelectorAll('.Accompaniment .swiper')
    }

    this.init()
  }

  init() {
    const {
      presentationCardsSwipers = null,
      relatedProfilesSwipers = null,
      relatedQuestions = null,
      accompanimentSwipers = null
    } = this.DOM

    if (presentationCardsSwipers && presentationCardsSwipers.length) {
      this.presentationSwipers = []

      presentationCardsSwipers.forEach(swiper => this.presentationSwipers.push(new MobileSwiper(swiper)))
    }

    if (relatedProfilesSwipers && relatedProfilesSwipers.length) {
      this.relatedProfilesSwipers = []

      relatedProfilesSwipers.forEach(swiper => this.relatedProfilesSwipers.push(new WknSwiper(swiper, { slidesPerView: 'auto', centerInsufficientSlides: true })))
    }

    if (relatedQuestions && relatedQuestions.length) {
      this.faqs = []

      relatedQuestions.forEach(questions => this.faqs.push(new Accordions(questions)))
    }

    if (accompanimentSwipers && accompanimentSwipers.length) {
      this.accompanimentSwipers = []

      accompanimentSwipers.forEach(swiper => this.accompanimentSwipers.push(new WknSwiper(swiper, { slidesPerView: 'auto', centerInsufficientSlides: true })))
    }
  }

  onLeaveCompleted() {
    this.presentationSwipers && this.presentationSwipers.length && this.presentationSwipers.forEach(swiper => swiper.destroy())
    this.relatedProfilesSwipers && this.relatedProfilesSwipers.length && this.relatedProfilesSwipers.forEach(swiper => swiper.destroy())
    this.faqs && this.faqs.length && this.faqs.forEach(faq => faq.destroy())
    this.accompanimentSwipers && this.accompanimentSwipers.length && this.accompanimentSwipers.forEach(swiper => swiper.destroy())
  }
}
