import WknSwiper from './wkn-swiper'

export default class WarrantyTables {
  constructor(container) {
    if (!container) return

    this.DOM = { container }

    // Navigation
    this.DOM.nav = this.DOM.container.querySelector(':scope nav')
    this.DOM.navCurrent = this.DOM.container.querySelector(':scope nav .current')
    this.DOM.navUl = this.DOM.container.querySelector(':scope nav ul')
    this.DOM.navButtons = this.DOM.container.querySelectorAll(':scope nav ul li button')
    this.DOM.dropdownButton = this.DOM.container.querySelector('.dropdown-button')

    // Tables
    this.DOM.tables = this.DOM.container.querySelectorAll('.WarrantyTable')
    this.DOM.tablesSwipers = this.DOM.container.querySelectorAll('.swiper')

    this.dropdownParameters = {
      state: false,
      className: '--show-items'
    }

    this.setEvents()
  }

  setEvents() {
    const { 
      navButtons = null,
      dropdownButton = null,
      tables = null,
      tablesSwipers = null 
    } = this.DOM

    if (!navButtons && !navButtons.length && !tables && !tables.length) return

    // Events
    this.switchTab = this.switchTab.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)

    navButtons.forEach(button => button.addEventListener('click', this.switchTab))
    dropdownButton.addEventListener('click', this.toggleDropdown)

    // Swipers
    this.swipers = []

    tablesSwipers.forEach(item => this.swipers.push(new WknSwiper(item, { slidesPerView: 'auto', spaceBetween: 0 })))
  }

  toggleDropdown(e) {
    e && e.preventDefault()
    
    this.DOM.nav.classList.toggle(this.dropdownParameters.className)

    setTimeout(() => document.addEventListener('click', this.closeDropdown), 50)
  }

  closeDropdown(e) {
    e && e.preventDefault()

    const { nav = null, navUl = null } = this.DOM

    if (!e.target.contains(navUl)) return

    nav.classList.remove(this.dropdownParameters.className)

    document.removeEventListener('click', this.closeDropdown)
  }

  switchTab(e) {
    e && e.preventDefault()

    const { nav, navButtons, navCurrent, tables } = this.DOM
    const currentButton = e.currentTarget
    const currentButtonIndex = [...navButtons].indexOf(currentButton)

    // Update styles of currentButton
    navButtons.forEach(button => button.classList.remove('--active'))
    currentButton.classList.add('--active')

    // Update current item (for mobile)
    navCurrent.textContent = currentButton.textContent
    nav.classList.remove(this.dropdownParameters.className)

    // Update tables
    tables.forEach(table => table.classList.remove('--active'))
    tables[currentButtonIndex].classList.add('--active')
  }

  destroy() {
    this.DOM.navButtons && this.DOM.navButtons.length && this.DOM.navButtons.forEach(button => button.removeEventListener('click', this.switchTab))
    this.DOM.dropdownButton && this.DOM.dropdownButton.removeEventListener('click', this.toggleDropdown)
    this.swipers && this.swipers.length && this.swipers.forEach(swiper => swiper.destroy())
  }
}
