import { Renderer } from '@unseenco/taxi';
import { viewStorage } from '../_globals'
import Form from '../modules/form'

export default class PageNewsletter extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      newsletterForm: viewStorage.current.querySelector('.Form')
    }
    this.init()
  }

  init() {
    const { newsletterForm = null } = this.DOM

    if (newsletterForm) this.formModule = new Form(newsletterForm)
  }

  onLeaveCompleted() {
    this.formModule && this.formModule.destroy()
  }
}
