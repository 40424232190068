import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import Accordions from '../modules/accordions'
import PresentationTabs from '../modules/presentation-tabs'

export default class SingleProfile extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      presentationTabs: viewStorage.current.querySelector('.PresentationTabs'),
      relatedQuestions: viewStorage.current.querySelector('.RelatedQuestions'),
    }

    this.init()
  }

  init() {
    const {
      testimonialsSwiper = null,
      presentationTabs = null,
      relatedQuestions = null,
    } = this.DOM

    if (testimonialsSwiper) this.testimonialsSwiperModule = new WknSwiper(testimonialsSwiper, { slidesPerView: '1' })
    if (presentationTabs) this.presentationTabsModule = new PresentationTabs(presentationTabs)
    if (relatedQuestions) this.accordionsModule = new Accordions(relatedQuestions)
  }

  onLeaveCompleted() {
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
    this.presentationTabsModule && this.presentationTabsModule.destroy()
    this.accordionsModule && this.accordionsModule.destroy()
  }
}
