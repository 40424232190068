import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Accordions from '../modules/accordions'
import WknSwiper from '../modules/wkn-swiper'

export default class PageHome extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      profilesOffers: viewStorage.current.querySelector('.ProfilesOffers'),
      accompanimentSwiper: viewStorage.current.querySelector('.Accompaniment .swiper'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper')
    }

    this.init()
  }

  init() {
    const { 
      profilesOffers = null,
      accompanimentSwiper = null,
      relatedPostsSwiper = null
    } = this.DOM

    if (profilesOffers) this.profilesOffersAccordionsModule = new Accordions(profilesOffers)
    if (accompanimentSwiper) this.accompanimentSwiperModule = new WknSwiper(accompanimentSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new WknSwiper(relatedPostsSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    this.profilesOffersAccordionsModule && this.profilesOffersAccordionsModule.destroy()
    this.accompanimentSwiperModule && this.accompanimentSwiperModule.destroy()
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
