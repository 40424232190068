/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'

import Popup from './modules/popup'
import NeedHelp from './modules/need-help'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

import { toggleNeedHelpPopin } from './modules/toggle-need-help-popin'
import { showCaptcha, toggleHeaderTheme } from './utils'

export let popup = null
export let need_help = null


export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)
  viewStorage.viewModals = new viewModals(domStorage.body)
  isDEVMODE && console.log('window', window)
  setTimeout(() => window.scrollTo(0, 0), 250)

  ScrollTrigger.refresh()



  // Header theme
  toggleHeaderTheme()

  // Intro
  GlobalIntro()

  // Need Help
  need_help = new NeedHelp()

  // Popup
  popup = new Popup()

  // Contact Popin
  toggleNeedHelpPopin()

  // Anchor
  isDEVMODE && console.log('window', window)
  if(window.location.hash) {
    const hash = window.location.hash.substring(1)
    const anchorEl = viewStorage.current.querySelector(`#${hash}`)

    if (!anchorEl) return

    gsap.to(window, {duration: 1.8, scrollTo: anchorEl, overwrite: 'all', ease: 'expo.inOut' })
  }
  isDEVMODE && console.log('window', window)
  let _paq = window._paq || []
  if (_paq) {
    const domains = document.body.dataset.matomoDomains.split(',')
      _paq.push(['setDomains', domains]);
      _paq.push(["enableCrossDomainLinking"]);
      _paq.push(['FormAnalytics::scanForForms']);
      _paq.push(['enableHeartBeatTimer']);
    setInterval(function() {
      _paq.push(['ping']);
    }, 5000);
  } else {
    console.log('NO MATOMO')
  }
  isDEVMODE && console.log('window', window)

  // Captcha
  showCaptcha()
}

export const onEnter = (to, trigger) => {
  domStorage.body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  // Header theme
  toggleHeaderTheme()

  // Contact Popin
  toggleNeedHelpPopin()

  viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()
  isDEVMODE && console.log('window', window)
  let _paq = window._paq || []
  if (_paq) {
    console.log('MATOMO TRACK BOTH DOMAINS')
    _paq.push(['setDomains', ['aiosante.fr', 'souscrire.aiosante.fr']]);
    _paq.push(["enableCrossDomainLinking"]);
    _paq.push(['FormAnalytics::scanForForms']);
  } else {
    console.log('NO MATOMO')
  }
  isDEVMODE && console.log('window', window)

}

export const onEnterCompleted = (to, from, trigger) => {
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Matomo
  isDEVMODE && console.log('window', window)
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('matomotm') && window._paq) {
    let _paq = window._paq || []
    _paq.push(['setCustomUrl', targetLocation.pathname]);
    _paq.push(['setDocumentTitle', to.page.title]);
    _paq.push(['trackPageView']);
  }

  // Captcha
  showCaptcha()

  // Need Help
  if (!need_help) need_help = new NeedHelp()
}

export const onLeave = (from, trigger) => {
  viewStorage.hasInView && viewStorage.viewInView.destroy()
  viewStorage.hasSticky && viewStorage.viewSticky.destroy()
  viewStorage.hasPrllx && viewStorage.viewPrllx.destroy()
  viewStorage.hasModals && viewStorage.viewModals.destroy()
  viewStorage.hasCursor && globalStorage.Cursor.removeHovers()

  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  globalStorage.menuOpen && globalStorage.closeMobileMenu()
  globalStorage.closeAllSubmenus()

  // Captcha
  showCaptcha()

  const { targetLocation } = globalStorage.taxi
  const navLinks = domStorage.menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

}
