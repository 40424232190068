import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class PageSimulationChoices extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      simulationContentSwiper: viewStorage.current.querySelector('.SimulationContent .swiper')
    }

    this.init()
  }

  init() {
    const { simulationContentSwiper = null } = this.DOM

    if (simulationContentSwiper) this.swiper = new WknSwiper(simulationContentSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })
  }

  onLeaveCompleted() {
    this.swiper && this.swiper.destroy()    
  }
}
