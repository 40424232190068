import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'
import WknSwiper from '../modules/wkn-swiper'

export default class SinglePost extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      wswygContent: viewStorage.current.querySelector('.wswyg--content'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper')
    }

    this.init()
  }

  init() {
    const { 
      wswygContent = null,
      relatedPostsSwiper = null
    } = this.DOM

    if (wswygContent) this.wysiwygWithVideosModule = new WysiwygWithVideos(wswygContent)
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new WknSwiper(relatedPostsSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    this.wysiwygWithVideosModule && this.wysiwygWithVideosModule.destroy()
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
