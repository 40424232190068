import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'

export default class PageOurStory extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      pageHeaderPlyr: viewStorage.current.querySelector('.PageHeaderOurStory .plyr__container')
    }

    this.init()
  }

  init() {
    const { 
      pageHeaderPlyr = null
    } = this.DOM

    if (pageHeaderPlyr) this.pageHeaderPlyrModule = new WknPlyr(pageHeaderPlyr)
  }

  onLeaveCompleted() {
    this.pageHeaderPlyrModule && this.pageHeaderPlyrModule.destroy()
  }
}
