import { domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Popup {
  constructor() {
    if (!domStorage.popup) return

    this.DOM = {
      inner: domStorage.popup.querySelector('.inner'),
      closeButton: domStorage.popup.querySelector('.Close')
    }

    // Set styles for the popup
    gsap.set(domStorage.popup, { opacity: 0 })
    gsap.set(domStorage.popup.children[0], { opacity: 0, yPercent: 20 })
    
    // Settings
    this.bodyClassName = '--show-popup'

    // Events for Close button
    this.setEvents()

    // Animations (IN / OUT)
    this.st = ScrollTrigger.create({
      trigger: viewStorage.current,
      once: true,
      start: '250px top',
      onEnter: () => this.setAnimations()
    })
  }

  setEvents() {
    const { closeButton } = this.DOM

    this.closePopup = this.closePopup.bind(this)

    closeButton.addEventListener('click', this.closePopup)
  }

  setAnimations() {
    this.tlIn = gsap
      .timeline({ 
        defaults: { ease: 'power2.inOut' }, 
        onStart: () => {
          // Add body className
          domStorage.body.classList.add(this.bodyClassName)

          // Stop the scroll
          viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()
        } 
      })
      .to(domStorage.popup, { opacity: 1, duration: 0.75 }, 0.1)
      .to(this.DOM.inner, { opacity: 1, yPercent: 0, duration: 0.5 }, 0.5)
  }

  closePopup(e) {
    e && e.preventDefault()

    this.tlOut = gsap
      .timeline({
        defaults: { ease: 'power2.inOut' },
        onComplete: () => {
          // Remove body className
          domStorage.body.classList.remove(this.bodyClassName)

          // Restart the scroll
          viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

          // Removing from the DOM
          domStorage.popup.remove()

          // Kill the timelines
          this.destroy()
        }
      })
      .to(this.DOM.inner, { opacity: 0, yPercent: -20, duration: 0.35 }, 0)
      .to(domStorage.popup, { opacity: 0, duration: 0.75 }, 0.1)
  }

  destroy() {
    this.tlIn && this.tlIn.kill()
    this.tlOut && this.tlOut.kill()
    this.st && this.st.kill()
  }
}