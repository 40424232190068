import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WarrantyTables from '../modules/warranty-tables'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageWarrantyDetails extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      warrantyTablesSection: viewStorage.current.querySelector('.WarrantyTables'),
      presentationCardsSwiper: viewStorage.current.querySelector('.PresentationCards .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const {
      warrantyTablesSection = null,
      presentationCardsSwiper = null
    } = this.DOM

    if (warrantyTablesSection) this.warrantyTablesModule = new WarrantyTables(warrantyTablesSection)
    if (presentationCardsSwiper) this.presentationCardsSwiperModule = new MobileSwiper(presentationCardsSwiper)
    
  }

  onLeaveCompleted() {
    this.warrantyTablesModule && this.warrantyTablesModule.destroy()
    this.presentationCardsSwiperModule && this.presentationCardsSwiperModule.destroy()
  }
}
