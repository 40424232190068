export default class PresentationTabs {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.navButtons = this.DOM.container.querySelectorAll('.tabs nav button')
    this.DOM.contents = this.DOM.container.querySelectorAll('.tabs .wrapper .wswyg--content')

    if (!this.DOM.navButtons && !this.DOM.navButtons.length && !this.DOM.contents && !this.DOM.contents.length) return

    this.activeClassName = '--active'

    this.setTabs()
  }

  setTabs() {
    const { navButtons } = this.DOM

    this.showTab = this.showTab.bind(this)

    navButtons.forEach(button => button.addEventListener('click', this.showTab))
  }

  showTab(e) {
    e && e.preventDefault()

    const { navButtons, contents } = this.DOM
    const currentTabButton = e.currentTarget
    const currentTabButtonIndex = [...navButtons].indexOf(currentTabButton)

    // Buttons
    navButtons.forEach(button => button.classList.remove(this.activeClassName))
    currentTabButton.classList.add(this.activeClassName)

    // Contents
    contents.forEach(content => content.classList.remove(this.activeClassName))
    contents[currentTabButtonIndex].classList.add(this.activeClassName)
  }

  destroy() {
    this.DOM.navButtons && this.DOM.navButtons.length && this.DOM.navButtons.forEach(button => button.removeEventListener('click', this.showTab))
  }
}
