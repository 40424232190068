import {Renderer} from '@unseenco/taxi';
import {viewStorage} from '../_globals'
import Form from '../modules/form'

export default class PageContact extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      contactForm: viewStorage.current.querySelector('.Form'),
      contact_who: viewStorage.current.querySelector('#contact_who'),
      contact_subject: viewStorage.current.querySelector('#contact_subject')
    }
    this.init()
  }

  init() {
    const {contactForm = null} = this.DOM
    const {contact_who = null} = this.DOM
    const {contact_subject = null} = this.DOM
    let options

    if (contactForm) this.formModule = new Form(contactForm)

    contact_who.addEventListener('change', () => {
      switch (contact_who.value) {
        case 'not_adeherent':
          options = [
            "Pour obtenir des informations sur nos offres santé",
            "Pour obtenir des informations sur nos offres de prévoyance maintien de salaire",
            "Pour obtenir des informations sur notre offre obsèques",
            "Pour être conseillé sur le choix de votre formule",
            "Pour obtenir des informations sur nos offres de prévention et assistance",
            "Pour devenir correspondant",
            "Autre demande"
          ]
          break;
        case 'correspondant':
          options = [
            "Pour obtenir des informations sur nos offres santé",
            "Pour obtenir des informations sur nos offres de prévoyance maintien de salaire",
            "Pour obtenir des informations sur notre offre obsèques",
            "Pour devenir correspondant",
            "Pour commander les supports correspondants",
            "Autre demande"
          ]
          break;
        case 'employeur':
          options = [
            "Pour obtenir des informations sur nos offres santé",
            "Pour obtenir des informations sur nos offres de prévoyance maintien de salaire",
            "Pour obtenir des informations sur notre offre obsèques",
            "Pour obtenir des informations sur les ateliers de prévention",
            "Pour devenir correspondant",
            "Autre demande"
          ]
          break;
        default:
          options = [
            "Vos remboursements/votre contrat",
            "Pour obtenir des informations sur nos offres santé",
            "Pour obtenir des informations sur nos offres de prévoyance maintien de salaire",
            "Pour obtenir des informations sur notre offre obsèques",
            "Pour être conseillé sur le choix de votre formule",
            "Pour obtenir des informations sur nos offres de prévention et assistance",
            "Pour devenir correspondant",
            "Autre demande"
          ]
          break
      }

      this.populateSelect(contact_subject, options);
    })
  }

  populateSelect(contact_subject, options) {
    let old_options = contact_subject.getElementsByTagName('option');

    for (let i = old_options.length; i--;) {
      contact_subject.removeChild(old_options[i]);
    }

    for (let i = 0; i < options.length; i++) {
      let opt = document.createElement('option');
      opt.value = options[i];
      opt.innerHTML = options[i];
      contact_subject.appendChild(opt);
    }
  }

  onLeaveCompleted() {
    this.formModule && this.formModule.destroy()
  }
}
