import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const breakpoint = 768

  // DOM
  const introBrand = domStorage.intro.querySelector('.Brand')
  const introInner = domStorage.intro.querySelector('.inner')

  gsap.set(domStorage.header, { yPercent: -100 })

  // Timeline
  const tl = gsap
    .timeline({
      defaults: { duration: 1, ease: 'power3.inOut' },
      onStart: () => {
        // Update body classNames
        domStorage.body.classList.remove('--preloading')
        domStorage.body.classList.remove('--loading')

        // Reset scroll with timing for Chrome
        setTimeout(() => window.scrollTo(0, 0), 25)
      },
      onComplete: () => {
        // Update firstLoad variable
        globalStorage.firstLoad = false

        // Remove home intro from the DOM
        domStorage.homeIntro && domStorage.homeIntro.remove()

        // Clear styles
        gsap.set(domStorage.header, { clearProps: 'yPercent' })
      }
    })

    // Only animate intro if the screen is > 768px
    if (window.innerWidth > breakpoint) {
      tl.fromTo(introBrand, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: 'power3.out' }, 0)
      tl.fromTo(introInner, { y: 0 }, { y: '75%' }, 0.8)
      tl.fromTo(domStorage.intro, { y: 0 }, { y: '-100%' }, 0.8)
      tl.add(pageIntro(), 0.9)
    } else {
      domStorage.intro && domStorage.intro.remove()
      tl.add(pageIntro(0), 0)
    }

  return tl
}
