import {Renderer} from '@unseenco/taxi';
import {isDEVMODE, viewStorage} from '../_globals'
import Form from '../modules/form'

export default class ArchivePosts extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      AllPostsForm: viewStorage.current.querySelector('.AllPosts form'),
      formTabs: viewStorage.current.querySelector('.FormTabs'),
      searchInput: viewStorage.current.querySelector('#search_posts'),
      pagination: viewStorage.current.querySelector('.Pagination'),
    }

    this.init()
    this.initForm()
  }

  initForm() {
    const AllCategoriesRadio = viewStorage.current.querySelectorAll('input[type=radio]')

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const category = urlParams.get('category')

    for (let radio of AllCategoriesRadio) {
      if (radio.value === category) {
        radio.checked = true
      }
    }
  }

  init() {

    const {
      AllPostsForm = null,
      formTabs = null,
      searchInput = null
    } = this.DOM

    if (AllPostsForm) this.formTabsModule = new Form(AllPostsForm)

    formTabs.addEventListener('change', () => {
      this.onFormChange();
    })

    searchInput.addEventListener('change', () => {
      this.onInputChange();
    })
  }

  onInputChange() {
    clearTimeout(this.timerDebounce);

    this.timerDebounce = setTimeout(() => {
      const input = viewStorage.current.querySelector('#search_posts')
      let queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.delete("search")

      queryString += `&search=${encodeURIComponent(input.value)}`;

      window.location = `?${queryString}#posts`

    }, 800);
  }

  onFormChange() {
    const categoryRadioChecked = viewStorage.current.querySelectorAll('input[type=radio]:checked')
    const categories = [...categoryRadioChecked].map((input) => input.value);

    let queryString = '';

    for (let category of categories) {
      queryString += `&category=${encodeURIComponent(category)}`;
      if(category === 'all') queryString = ''
    }


    let str = window.location.pathname

    let test = str.substring(0, str.length - 7)

    window.location = test+`?${queryString}#posts`

  }

  onLeaveCompleted() {
    this.formTabsModule && this.formTabsModule.destroy()
  }
}
