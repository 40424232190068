import './routing'

/*
Load Plugins / Functions
-------------------------------------------------- */
import { isDEVMODE, globalStorage, domStorage, viewStorage } from './_globals'
import { onReady } from './_events'
import Header from './modules/header'
import Footer from './modules/footer'
import WknHelperGrid from './modules/wkn-helper-grid'

class App {
  constructor() {
    const made = 'font-family: system-ui, -apple-system, BlinkMacSystemFont, sans-serif; font-size: 14px; color: #ffffff; padding: 5px 0;'
    const website = 'font-size: 10px; padding-bottom: 5px; color: #ffffff;'
    console.log('%cMade by Wokine' + '\n%chttps://wokine.com', made, website)

    globalStorage.Header = new Header()
    // globalStorage.Footer = new Footer()

    // Helper Grid
    if (isDEVMODE) globalStorage.WknHelperGrid = new WknHelperGrid()

    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => onReady())
    })
  }
}

window.app = new App()
