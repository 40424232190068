import { viewStorage, domStorage } from '../_globals'

export const toggleNeedHelpPopin = () => {
  // Check if the contact popin exists
  if (!domStorage.needHelp) return

  // Get the current page data attributes
  const current_page = viewStorage.current.getAttribute('data-taxi-view')
  // Pages we don't want to show the popin
  const pagesWithoutPopin = current_page === 'pageContact' || current_page === 'pageCallback'
  // Hide className
  const bodyClassName = '--show-need-help'

  if (pagesWithoutPopin) {
    domStorage.body.classList.remove(bodyClassName)
  } else {
    if (domStorage.body.classList.contains(bodyClassName)) return

    setTimeout(() => domStorage.body.classList.add(bodyClassName), 5000)
  }
}
