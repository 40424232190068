import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import PresentationTabs from '../modules/presentation-tabs'
import Offers from '../modules/offers'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import Accordions from '../modules/accordions'

export default class OfferDetails extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      presentationTabs: viewStorage.current.querySelector('.PresentationTabs'),
      ourOffers: viewStorage.current.querySelector('.OurOffers'),
      formulasMobileSwipers: viewStorage.current.querySelectorAll('.Formulas .swiper.--mobile'),
      presentationCardsSwiper: viewStorage.current.querySelector('.PresentationCards .swiper.--mobile'),
      accompanimentSwiper: viewStorage.current.querySelector('.Accompaniment .swiper'),
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      relatedQuestions: viewStorage.current.querySelector('.RelatedQuestions')
    }

    this.init()
  }

  init() {
    const {
      presentationTabs = null,
      ourOffers = null,
      formulasMobileSwipers = null,
      presentationCardsSwiper = null,
      accompanimentSwiper = null,
      testimonialsSwiper = null,
      relatedQuestions = null
    } = this.DOM

    this.formulasMobileSwipersModules = []

    if (presentationTabs) this.presentationTabsModule = new PresentationTabs(presentationTabs)
    if (ourOffers) this.ourOffersModule = new Offers(ourOffers)
    if (formulasMobileSwipers && formulasMobileSwipers.length) formulasMobileSwipers.forEach(swiper => this.formulasMobileSwipersModules.push(new MobileSwiper(swiper)))
    if (presentationCardsSwiper) this.presentationCardsSwiperModule = new MobileSwiper(presentationCardsSwiper)
    if (accompanimentSwiper) this.accompanimentSwiperModule = new WknSwiper(accompanimentSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })
    if (testimonialsSwiper) this.testimonialsSwiperModule = new WknSwiper(testimonialsSwiper, { slidesPerView: '1' })
    if (relatedQuestions) this.accordionsModule = new Accordions(relatedQuestions)
  }

  onLeaveCompleted() {
    this.presentationTabsModule && this.presentationTabsModule.destroy()
    this.ourOffersModule && this.ourOffersModule.destroy()
    this.formulasMobileSwipersModules && this.formulasMobileSwipersModules.length && this.formulasMobileSwipersModules.forEach(swiper => swiper.destroy())
    this.presentationCardsSwiperModule && this.presentationCardsSwiperModule.destroy()
    this.accompanimentSwiperModule && this.accompanimentSwiperModule.destroy()
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
    this.accordionsModule && this.accordionsModule.destroy()
  }
}
