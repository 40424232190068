import { Renderer } from '@unseenco/taxi';
import { viewStorage } from '../_globals'
import Form from '../modules/form'

export default class PageReferral extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      referralForm: viewStorage.current.querySelector('.Form')
    }
    this.init()
  }

  init() {
    const { referralForm = null } = this.DOM

    if (referralForm) this.formModule = new Form(referralForm)
  }

  onLeaveCompleted() {
    this.formModule && this.formModule.destroy()
  }
}
