import { domStorage, viewStorage } from '../_globals'
import WknSwiper from './wkn-swiper'

export default class Offers {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.navButtons = this.DOM.container.querySelectorAll('.offers nav button')
    this.DOM.swiper = this.DOM.container.querySelector('.swiper')
    this.DOM.swiperSlides = this.DOM.container.querySelectorAll('.swiper-slide')

    this.activeClassName = '--active'

    this.setSwiper()
    this.setEvents()
  }

  setSwiper() {
    const { 
      navButtons = null,
      swiper = null, 
      swiperSlides = null
    } = this.DOM

    if (!swiper) return

    this.offersSwiper = new WknSwiper(swiper, {
      slidesPerView: 'auto',
      centeredSlides: true
    })

    this.offersSwiper.swiper.on('slideChange', e => {
      const activeIndex = e.activeIndex

      // Update style of buttons
      navButtons.forEach(button => button.classList.remove(this.activeClassName))
      navButtons[activeIndex].classList.add(this.activeClassName)

      // Update dataset on current page (to adapt conditional displays)
      viewStorage.current.dataset.activeOffer = `${activeIndex + 1}`
    })
  }

  setEvents() {
    const { navButtons = null } = this.DOM

    if (!navButtons && !navButtons.length) return

    this.updateTab = this.updateTab.bind(this)
    
    navButtons.forEach(button => button.addEventListener('click', this.updateTab))
  }

  updateTab(e) {
    e && e.preventDefault()

    const { navButtons } = this.DOM

    const currentButton = e.currentTarget
    const currentButtonIndex = [...navButtons].indexOf(currentButton)

    // Update style of buttons
    navButtons.forEach(button => button.classList.remove(this.activeClassName))
    currentButton.classList.add(this.activeClassName)

    // Update swiper slides
    this.offersSwiper.swiper.slideTo(currentButtonIndex)

    // Update dataset on current page (to adapt conditional displays)
    viewStorage.current.dataset.activeOffer = `${currentButtonIndex + 1}`
  }

  destroy() {
    this.offersSwiper && this.offersSwiper.destroy()
    this.DOM.navButtons && this.DOM.navButtons.length && this.DOM.navButtons.forEach(button => button.removeEventListener('click', this.updateTab))
  }
}
