import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    // Brand logo
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    // Toggle for mobile version
    this.DOM.toggle = this.DOM.el.querySelector('.NavToggle')
    // Navigation
    this.DOM.navContainer = this.DOM.el.querySelector('.NavContainer')
    this.DOM.navMain = this.DOM.el.querySelector('.Nav.--main')
    this.DOM.navButtons = this.DOM.el.querySelector('.Nav.--buttons')
    this.DOM.navItemsWithSubmenu = this.DOM.navMain.querySelectorAll('ul li.--submenu > .NavLink')
    
    this.currentSubmenu = null
    this.activeSubmenuClassName = '--open'

    this.setEvents()
  }

  setEvents() {
    const { overlay } = domStorage
    const { toggle,  navItemsWithSubmenu = null } = this.DOM

    if (navItemsWithSubmenu && navItemsWithSubmenu.length) {
      this.toggleSubmenu = this.toggleSubmenu.bind(this)
      this.closeSubmenu = this.closeSubmenu.bind(this)

      navItemsWithSubmenu.forEach(item => item.addEventListener('click', this.toggleSubmenu))
    }

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    // Open Mobile
    globalStorage.openMobileMenu = () => this.open()

    // Close Mobile
    globalStorage.closeMobileMenu = () => this.close()
    
    // Close Submenu
    globalStorage.closeSubmenu = () => this.closeSubmenu()
    globalStorage.closeAllSubmenus = () => this.closeAllSubmenus()

    // Overlay
    overlay.addEventListener('click', () => globalStorage.menuOpen && this.close())

    // Scroll
    this.setObserver()

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  open() {
    globalStorage.menuOpen = true
    domStorage.body.classList.add('--show-menu')
  }

  close() {
    globalStorage.menuOpen = false
    domStorage.body.classList.remove('--show-menu')
  }

  toggleSubmenu(e) {
    e && e.preventDefault()

    const currentNavItem = e.currentTarget.parentNode

    // Close previous submenu
    this.currentSubmenu && this.currentSubmenu.classList.remove(this.activeSubmenuClassName)

    // ClassNames for the current submenu
    if (this.currentSubmenu === currentNavItem) {
      // Close if same
      this.currentSubmenu.classList.remove(this.activeSubmenuClassName)
      this.currentSubmenu = null
      return
    } else {
      // Otherwise, open the submenu
      this.currentSubmenu = currentNavItem
      this.currentSubmenu.classList.toggle(this.activeSubmenuClassName)
    }

    // Update the current submenu variable
    this.currentSubmenu = currentNavItem

    // Add the event on document for click outside
    setTimeout(() => document.addEventListener('click', this.closeSubmenu), 50)
  }

  closeSubmenu(e) {
    if (!this.currentSubmenu) return

    e && e.stopPropagation()

    if (this.currentSubmenu.contains(e.target)) return

    // Remove className for the current submenu
    this.currentSubmenu.classList.remove(this.activeSubmenuClassName)

    // Adapt the currentSubmenu variable
    this.currentSubmenu = null

    // Remove the event on document
    document.removeEventListener('click', this.closeSubmenu)
  }

  closeAllSubmenus() {
    if (!this.currentSubmenu) return

    this.currentSubmenu.classList.remove(this.activeSubmenuClassName)
    
    this.currentSubmenu = null
  }

  setObserver() {
    const { body } = domStorage
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
      onDown: () => {
        el.classList.remove('--visible') 
        // We close all the submenus when the user scrolls (better UX)
        this.closeAllSubmenus()
      }
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 20 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 20 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })
  }

  onResize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen) this.close()
        this.closeSubmenu()
      }
    }, 250)
  }
}
