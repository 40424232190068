import { domStorage } from '../_globals'
import gsap from 'gsap'

export default class NeedHelp {
  constructor() {
    if (!domStorage.needHelp) return

    // DOM
    this.DOM = {
      toggleButtonMobile: domStorage.needHelp.querySelector('button.toggle-mobile'),
      closeButtonDesktop: domStorage.needHelp.querySelector('button.close-popup-desktop'),
    }

    // Settings
    this.bodyClassName = '--show-need-help'
    this.bodyClassNameMobile = '--show-need-help-mobile'

    // Events
    this.toggleMobile = this.toggleMobile.bind(this)
    this.close = this.close.bind(this)

    this.DOM.toggleButtonMobile && this.DOM.toggleButtonMobile.addEventListener('click', this.toggleMobile)
    this.DOM.closeButtonDesktop & this.DOM.closeButtonDesktop.addEventListener('click', this.close)
  }

  toggleMobile(e) {
    e && e.preventDefault()

    domStorage.body.classList.toggle(this.bodyClassNameMobile)
  }

  open(e) {
    e && e.preventDefault()

    domStorage.body.classList.add(this.bodyClassName)
  }

  close(e) {
    e && e.preventDefault()

    domStorage.body.classList.remove(this.bodyClassName)
  }

  destroy() {
    this.DOM.toggleButtonMobile && this.DOM.toggleButtonMobile.removeEventListener('click', this.toggleMobile)
    this.DOM.toggleButtonMobile && this.DOM.closeButton.removeEventListener('click', this.close)
  }
}
