import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import PresentationTabs from '../modules/presentation-tabs'
import Accordions from '../modules/accordions'

export default class PageLabeledOffer extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      relatedProfilesSwiper: viewStorage.current.querySelector('.RelatedProfiles .swiper'),
      presentationTabs: viewStorage.current.querySelector('.PresentationTabs'),
      relatedQuestions: viewStorage.current.querySelector('.RelatedQuestions')
    }

    this.init()
  }

  init() {
    const {
      relatedProfilesSwiper = null,
      presentationTabs = null,
      relatedQuestions = null
    } = this.DOM

    if (relatedProfilesSwiper) this.relatedProfilesSwiperModule = new WknSwiper(relatedProfilesSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })
    if (presentationTabs) this.presentationTabsModule = new PresentationTabs(presentationTabs)
    if (relatedQuestions) this.accordionsModule = new Accordions(relatedQuestions)
  }

  onLeaveCompleted() {
    this.relatedProfilesSwiperModule && this.relatedProfilesSwiperModule.destroy()
    this.presentationTabsModule && this.presentationTabsModule.destroy()
    this.accordionsModule && this.accordionsModule.destroy()
  }
}
